import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { Navbar, Footer } from '@/components'
import { scrollTop } from '@/utils/helper'

import './styles.css'

function index() {
  useEffect(() => {
    scrollTop()
  }, [])

  return (
    <Box className={`body-wrapper`}>
      <Navbar />
      {/* Code here */}
      <Box className={`wrapper--law`}>
        <Box className={`bg--banner`}>
          <h1 className={`title_h1--banner`}>特定商取引法に基づく表示</h1>
        </Box>
        <Box className={`main--law`}>
          <Box className={`container container__1154`}>
            <Box className={`title_head_law`}>特定商取引法に基づく表示</Box>
            <Box className={`body_Table`}>
              <Box className={`item_Table`}>
                <Box className={`table_left itemTD`}>販売業者の名称</Box>
                <Box className={`table_right itemTD`}>株式会社フィンチジャパン</Box>
              </Box>
              <Box className={`item_Table`}>
                <Box className={`table_left itemTD`}>商品の種類・サイト名</Box>
                <Box className={`table_right itemTD`}>
                  クラウドアプリケーションサービス
                  <br />
                  フィンチジャパンクラウドのSaaS  https://infolio.ai
                </Box>
              </Box>
              <Box className={`item_Table`}>
                <Box className={`table_left itemTD`}>販売業者の住所・電話番号</Box>
                <Box className={`table_right itemTD`}>
                  東京都渋谷区恵比寿西一丁目33番6号 JPnoie恵比寿西 1F／2F
                  <br />
                  ０３－４５９０―５９６４
                </Box>
              </Box>
              <Box className={`item_Table`}>
                <Box className={`table_left itemTD`}>販売業者の連絡先</Box>
                <Box className={`table_right itemTD`}>infolio@finchjapan.co.jp</Box>
              </Box>
              <Box className={`item_Table`}>
                <Box className={`table_left itemTD`}>運営責任者</Box>
                <Box className={`table_right itemTD`}>
                  株式会社フィンチジャパン
                  <br />
                  代表取締役：髙橋広嗣
                </Box>
              </Box>
              <Box className={`item_Table`}>
                <Box className={`table_left itemTD`}>商品の価格</Box>
                <Box className={`table_right itemTD`}>お問合せフォームにてご連絡ください。</Box>
              </Box>
              <Box className={`item_Table`}>
                <Box className={`table_left itemTD`}>ご契約期間</Box>
                <Box className={`table_right itemTD`}>１ヶ月（サブスクリプション）</Box>
              </Box>
              <Box className={`item_Table`}>
                <Box className={`table_left itemTD`}>キャンセルについて</Box>
                <Box className={`table_right itemTD`}>
                  デジタルコンテンツの特性上、返品・交換は受け付けておりません。月の途中の解約（退会）となった場合、解約日を含む解約月
                  <br />
                  の月末までの１ヶ月分の料金が発生し、日割清算等による返金を
                  <br />
                  含めた一切の返金は行われません。
                  <br />
                  解約成立の翌月より請求を停止いたします。
                  <br />
                  解約方法につきましては、サービス利用規約をご参照ください。
                  <br />
                  ご不明点はお問合せフォームよりご連絡ください。
                </Box>
              </Box>
              <Box className={`item_Table`}>
                <Box className={`table_left itemTD`}>商品以外の必要金額</Box>
                <Box className={`table_right itemTD`}>振り込み手数料</Box>
              </Box>
              <Box className={`item_Table`}>
                <Box className={`table_left itemTD`}>支払方法</Box>
                <Box className={`table_right itemTD`}>銀行振込</Box>
              </Box>
              <Box className={`item_Table`}>
                <Box className={`table_left itemTD`}>サービス利用開始日</Box>
                <Box className={`table_right itemTD`}>
                  利用開始日は、別途通知するものとします。
                  <br />
                  詳細はサービス利用規約をご参照ください。
                </Box>
              </Box>
              <Box className={`item_Table`}>
                <Box className={`table_left itemTD`}>支払期限</Box>
                <Box className={`table_right itemTD`}>
                  2週間のお試し期間後、銀行振込にてお支払い頂きます。
                  <br />
                  詳細はサービス利用規約をご参照ください。
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default index
