import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { externalLinkWhite  } from '@/utils/images'
import { Navbar, Footer } from '@/components'
import { scrollTop } from '@/utils/helper'
import { Link } from 'react-router-dom'

import './styles.css'

function index() {
  useEffect(() => {
    scrollTop()
  }, [])

  return (
    <Box className={`body-wrapper`}>
      <Navbar />
      <Box className={`wrapper--policy`}>
        <Box className={`bg--banner`}>
          <h1 className={`title_h1--banner`}>ベータ版無期限無償トライアルのご案内</h1>
        </Box>
        <Box className={`main--policy`}>
          <Box className={`container container__1230`}>
            <Box className={`content--txt`}>
              現在、InFolioはベータ版として、「AI検索」、及び「PieceFolio」の２つの機能を提供しておりますが、製品版のリリースに向け、これらの機能を使ってくださるトライアルユーザーを探しております。
              <br />
              お申し込みいただくと製品版リリースまで無償トライアルとしてご利用いただくことが可能で、製品版リリースの際に有料契約頂いた場合には、そのままアカウントを継続してご利用いただけます。
              <br />
              <br />
              弊ツールをより良いものとするためにも、なるべく多くの方からフィードバックを頂きたいと考えており、使用をご検討の皆様は、ぜひこの期間にご利用いただければと考えております。
              <br />
              <br />
              <br />
              <h4 className={`title__h4`}>トライアルご利用のお手続きについて</h4>
              トライアルのご利用には利用申込書のご提出が必要です。
              <br />
              ご希望の方にはトライアルの利用申込書をお送りいたしますので、コンタクトフォームから「InFolioトライアル希望」とご記載の上、ご連絡ください。
              <br />
              <br />
              <Box className={`scroll--contact`}>
                <Link
                  to='https://company.finchjapan.co.jp/contact/'
                  target='_bank'
                  className={`btnContact`}
                >
                  問い合わせ
                  <img className={`imgExternalLink`} src={externalLinkWhite} width={14} />
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default index
