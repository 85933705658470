import React, { useRef, useEffect, useLayoutEffect, useState } from 'react'
import { getUserToken, getUser } from '@/utils/localData'
import { Navigate } from 'react-router-dom'
import { INVITED } from '@/utils/constant'
import moment from 'moment/moment'
import {FORMAT_DATE_CAPITAL} from '@/utils/constant'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import {
  blockTop,
  blockBottom,
  logoLoading,
  textLogo,
  imgGif,
  ltTop,
  ltBottom,
  ltTopSP,
  ltBottomSP,
  imgBgWhatIs,
  imgMacInfolio,
  imgBgFeature,
  imgBgValue,
  imgBgNews,
  imgBgContact,
  bgBannerTop,
  iconScrollBl,
  imgWhatIsMac,
  iconScroll,
  feature1,
  feature2,
  feature3,
  feature4,
  iconTxt1,
  iconTxt2,
  iconTxt3,
  iconTxt4,
  imgDetailVl1,
  imgDetailVl2,
  news1,
  iconNtx,
} from '@/utils/images'
import { Navbar, Footer } from '@/components'
import useScript from '@/hooks/useScript'
import { scrollTop } from '@/utils/helper'
import ContactUs from './ContactUs'
import './styles.css'
import './wow.css'

const index = () => {
  const userToken = getUserToken()
  const user = getUser()
  const bodyWraperRef = useRef()

  const [data, setData] = useState({
    releases: [],
    media: [],
    awards: [],
    allPosts: [],
  });

	console.log("data: ", data)

	const urls = [
    { key: 'releases', url: process.env.REACT_APP_MEDIA_URL+ '/wp-json/wl/v1/release' },
    { key: 'media', url: process.env.REACT_APP_MEDIA_URL + '/wp-json/wl/v2/media' },
    { key: 'awards', url: process.env.REACT_APP_MEDIA_URL + '/wp-json/wl/v3/award' },
    { key: 'allPosts', url: process.env.REACT_APP_MEDIA_URL + '/wp-json/wl/v4/allpost' }
  ];

  // if (userToken && user.status != INVITED) return <Navigate to='/top' />

  useScript('dist/js/main.js')

  useLayoutEffect(() => {
    return () => {
      bodyWraperRef.current.className = ''
      scrollTop()
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promises = urls.map(async ({ key, url }) => {
          const response = await fetch(url);
          const result = await response.json();
          return { key, data: result };
        });

        const results = await Promise.all(promises);
        
        const newData = results.reduce((acc, { key, data }) => {
          acc[key] = data;
          return acc;
        }, {});

        setData(newData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box ref={bodyWraperRef} className={`body-wrapper`}>
      <Box className={`loading--all-page`} id='loading--page'>
        <Box className={`block-top`}>
          <img className={`img-fluid w-100 transition-08s m-pc575`} src={blockTop} />
          <img className={`img-fluid w-100 transition-08s m-sp575`} src={ltTopSP} />
        </Box>
        <Box className={`loading--logo`}>
          <Box className={`block--logo`}>
            <img className={`img-fluid logo-loading transition-06s`} src={logoLoading} />
            <img className={`img-fluid text--logo transition-06s`} src={textLogo} />
            <Box className={`loading--item transition-1s`}>
              <img className={`img-fluid img--gif transition-1s`} src={imgGif} />
            </Box>
          </Box>
        </Box>
        <Box className={`bg--ld-top`}>
          <img className={`img-fluid`} src={ltTop} />
        </Box>
        <Box className={`bg--ld-bottom`}>
          <img className={`img-fluid`} src={ltBottom} />
        </Box>
        <Box className={`block-bottom `}>
          <img className={`w-100 img-fluid transition-08s m-pc575`} src={blockBottom} />
          <img className={`w-100 img-fluid transition-08s m-sp575`} src={ltBottomSP} />
        </Box>
      </Box>
      <Navbar />
      <Box className={`bg--all-page`}>
        <Box className={`image--banner-what banner--top transition--2s-slow`}>
          <Box className={`image--bg`}>
            <img className={`img-fluid w-100`} src={imgBgWhatIs} alt='Information Portfolio' />
          </Box>
          <Box className={`image--mac transition-1s`}>
            <img src={imgMacInfolio} alt='Information Portfolio' />
          </Box>
        </Box>
        <Box className={`image--banner-feature transition-06s`}>
          <img className={`img-fluid w-100 transition-08s`} src={imgBgFeature} alt='Feature' />
        </Box>
        <Box className={`image--banner-value transition-06cubic`}>
          <img className={`img-fluid w-100 transition-06cubic`} src={imgBgValue} alt='Value' />
        </Box>
        <Box className={`image--banner-news transition-06cubic`}>
          <img className={`img-fluid w-100 transition-06cubic`} src={imgBgNews} alt='News' />
        </Box>
        <Box className={`image--banner-contact transition-06cubic`}>
          <img
            className={`img-fluid w-100 transition-06cubic bg-news-contact`}
            src={imgBgNews}
            alt='News'
          />
          <img
            className={`img-fluid w-100 transition-06cubic bg-contact`}
            src={imgBgContact}
            alt='Contact'
          />
        </Box>
      </Box>
      {/* Page */}
      <Box className={`lp--banner lp--all-page section--Page lp--whatIs`}>
        <Box className={`h2-title--head h2--whatIs text-center transition-06s`}>
          What is InFolio?
        </Box>
        <Box className={`h2-title--head h2--feature transition-06s`}>Features</Box>
        <Box className={`top__scroll-slider scrollSync slider__scrollbar transition--1s-slow`}>
          <Box className={`scroll__item`}></Box>
        </Box>
        <Box className={`title--head-value title--head-option2 transition--1s-slow`}>
          <Box className={`h2--value`}>Values</Box>
        </Box>
        <Box className={`text--txt title--head-news title--head-option2 transition--1s-slow`}>
          <Box className={`h2--value `}>News</Box>
        </Box>
        <Box
          className={`title--head-contact title--head-option2 title--head-option3 transition--1s-slow`}
        >
          <Box className={`h2--value h2--contact`}>Contact us</Box>
          <Box className={`text--txt`}>
            <span>InFolio</span>について詳しく知りたい方へ
          </Box>
        </Box>

        <Box className={`banner--top`}>
          <Box className={`image--bg`}>
            <img className={`img-fluid w-100`} src={bgBannerTop} alt='Information Portfolio' />
          </Box>
        </Box>
        <Box className={`banner--bottom transition-1s`}>
          <h1 className={`titleH1`}>
            Info<span>rmation</span> + <span>Port</span>folio = InFolio
          </h1>
          <div className={`description-txt`}>
            世界中の情報をAIが収集、
            <br />
            あなたの代わりにレポートを作成する
          </div>
          <Box className={`banner--btn`}>
            <Link
              to='https://company.finchjapan.co.jp/contact/'
              target='_bank'
              className={`btn--ctact
                    scroll--contact`}
            >
              お問い合わせ
            </Link>
            <Link to='/' className={`btn--what`}>
              What is InFolio?
            </Link>
          </Box>
          <Box className={`banner--btn`}>
            <Link to='/beta-trial' className={`btn--ctact
                    scroll--contact`}>
              無料トライアル
            </Link>
          </Box>
        </Box>
        <Box className={`reserved-i transition--1s-slow`}>
          ©Finchjapan, 2024. All rights reserved.
        </Box>
        <Box className={`block--scroll block-scroll--whatIs transition-1s`}>
          <Box className={`scroll--nt scroll--what`}>
            <img className={`img-fluid `} src={iconScrollBl} alt='What is InFolio?' />
            <span>What is InFolio?</span>
          </Box>
        </Box>
        {/* whatIs */}
        <Box className={`image--whatIs text-center transition--1s-slow`}>
          <img className={`img-fluid`} src={imgWhatIsMac} alt='What is InFolio' />
        </Box>
        <Box className={`What--bottom-txt text-center transition-06s`}>
          <Box className={`text--txt`}>
            InFolioとは、情報検索と管理を一元化できるクラウドベースの法人向け検索プラットフォームです。
            <br />
            インターネット接続さえあれば場所を選ばずアクセス可能で、検索結果を自動要約し、
            <br />
            共有、出力までの一連の業務を半自動化できます。
          </Box>
        </Box>
        <Box className={`block--scroll block-scroll--feature`}>
          <Box className={`scroll--nt scrollEC mb-50`}>
            <img className={`img-fluid imgRotate180`} src={iconScroll} alt='Top' />
            <span>Top</span>
          </Box>
          <Box className={`scroll--nt scroll--feature`}>
            <img className={`img-fluid`} src={iconScroll} alt='Features' />
            <span>Features</span>
          </Box>
        </Box>
        {/* feature */}
        <Box className={`slider--wrapper wow fadeInRight`} data-wow-delay='0.25s'>
          <Box className={`slider--feature none-scroll-bar scrollSync`} id='slider_Feature'>
            <Box className={`slider__Feature`}>
              <Box className={`item--li`}>
                <Box className={`item-feature`}>
                  <Box className={`top--item`}>
                    <Box className={`image--item`}>
                      <img className={`img-fluid `} src={feature1} alt='AI検索' />
                    </Box>
                    <Box className={`cate--top`}>
                      <Box className={`cat`}>
                        <img className={`img-fluid`} src={iconTxt1} alt='' />
                        <span>AI検索</span>
                      </Box>
                      <Box className={`txt-text`}>キーワードや自由記述から瞬時に検索</Box>
                    </Box>
                    <Box className={`bottom--item`}>
                      キーワードや自由記述から瞬時に検索・要約。様々なデータを、常に最新のオンラインリソースから素早く取得します。
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={`item--li`}>
                <Box className={`item-feature`}>
                  <Box className={`top--item`}>
                    <Box className={`image--item`}>
                      <img className={`img-fluid `} src={feature2} alt='ベンチマーク' />
                    </Box>
                    <Box className={`cate--top`}>
                      <Box className={`cat`}>
                        <img className={`img-fluid `} src={iconTxt2} alt='' />
                        <span>PieceFolio</span>
                      </Box>
                      <Box className={`txt-text`}>
                        業界や調査対象を絞った検索を半自動化し、検索結果の要約を出力
                      </Box>
                    </Box>
                    <Box className={`bottom--item`}>
                      独自の技術で定量的な調査を加速する機能。
                      <br />
                      情報の信頼性を保ったまま、繰り返しの作業を生みやすいベンチマーク調査や競合調査などを半自動化します。
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={`item--li`}>
                <Box className={`item-feature`}>
                  <Box className={`top--item`}>
                    <Box className={`image--item`}>
                      <img className={`img-fluid`} src={feature3} alt='リソース提示' />
                    </Box>
                    <Box className={`cate--top`}>
                      <Box className={`cat`}>
                        <img className={`img-fluid`} src={iconTxt3} alt='' />
                        <span>リソース表示</span>
                      </Box>
                      <Box className={`txt-text`}>
                        すべての検索結果に対して回答の根拠となるリソースを表示
                      </Box>
                    </Box>
                    <Box className={`bottom--item`}>
                      InFolioを使用して検索した内容については、回答の根拠となるリソースを必ず表示。
                      <br />
                      ハルシネーションが起こる確率を下げるだけでなく、ご自身での事実確認も容易です。
                      {/* <span className={`note-i`}>
                        *ハルシネーション：不正確だったり、誤っていたりする情報を、あたかも正しいように生成してしまうこと。
                      </span> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={`item--li`}>
                <Box className={`item-feature`}>
                  <Box className={`top--item`}>
                    <Box className={`image--item`}>
                      <img className={`img-fluid`} src={feature4} alt='共有・出力機能' />
                    </Box>
                    <Box className={`cate--top`}>
                      <Box className={`cat`}>
                        <img className={`img-fluid`} src={iconTxt4} alt='' />
                        <span>共有・出力機能</span>
                      </Box>
                      <Box className={`txt-text`}>
                        検索結果をすばやく共有
                      </Box>
                    </Box>
                    <Box className={`bottom--item`}>
                      共有機能を使えば、検索結果をInFolio上で瞬時に共有。共同編集も可能。
                      <br />
                      自社テンプレートを使用したPowerPointへの出力も可能です。
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={`block--scroll block-scroll--value transition-1s`}>
          <Box className={`scroll--nt scroll--what mb-50`}>
            <img className={`img-fluid imgRotate180`} src={iconScroll} alt='What is InFolio?' />
            <span>What is InFolio?</span>
          </Box>
          <Box className={`scroll--nt scroll--value-detail`}>
            <img className='img-fluid ' src={iconScroll} alt='Values' />
            <span>Values</span>
          </Box>
        </Box>
        <Box className={`value--detail transition--1s-slow`}>
          <Box className={`body--form`}>
            <Box className={`tab-wrapper`}>
              <Box component={'ul'} className={`tabs`}>
                <Box component={'li'} className={`tab-link active`} data-tab='1'>
                  Value 1
                </Box>
                <Box component={'li'} className={`tab-link`} data-tab='2'>
                  Value 2
                </Box>
              </Box>
            </Box>
            <Box className={`content-wrapper none-scroll-bar`}>
              <Box id='tab-1' className={`tab-1 tab-content active`}>
                <Box className={`body--content-wp`}>
                  <Box className={`image--ct-value`}>
                    <img
                      className='img-fluid '
                      src={imgDetailVl1}
                      alt='人的リソースをより創造的な活動へ'
                    />
                  </Box>
                  <Box className={`content--ct-value`}>
                    <Box className={`head--top`}>
                      <h3 className={`title--dt-head`}>Value 1</h3>
                      <Box className={`des--txt-i`}>人的リソースをより創造的な活動へ</Box>
                    </Box>
                    <Box className={`txt--content-i`}>
                      InFolioは、繰り返し作業となりやすい情報収集やBM調査を半自動化し、これらにかかる時間を短縮することができます。こういった業務時間の削減により、ユーザーは事業開発や顧客との関係構築など、より付加価値の高いクリエイティブな業務に時間を割り当てることが可能になり、生産性の向上だけでなく、従業員の仕事への満足度の向上も期待できます。
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box id='tab-2' className={`tab-2 tab-content`}>
                <Box className={`body--content-wp`}>
                  <Box className={`image--ct-value`}>
                    <img className='img-fluid ' src={imgDetailVl2} alt='情報を自動で共有知化' />
                  </Box>
                  <Box className={`content--ct-value`}>
                    <Box className={`head--top`}>
                      <h3 className={`title--dt-head`}>Value 2</h3>
                      <Box className={`des--txt-i`}>情報を自動で共有知化</Box>
                    </Box>
                    <Box className={`txt--content-i`}>
                      InFolioの導入により、情報は見える化され、必要な時に必要な人々がアクセスできるようになります。
                      <br />
                      これにより、チームメンバーはそれぞれの専門知識を活かしながら、共有されたデータを基にして協力し、複雑な問題を効果的に解決することが可能です。また、InFolioのライブラリに貯められる知見は、御社の方向性を示す重要な情報となり、その後の新規事業立案業務の重要な礎にもなります。
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={`block--scroll block-scroll--news-detail transition-1s`}>
          <Box className={`scroll--nt scroll--feature mb-50`}>
            <img className={`img-fluid imgRotate180`} src={iconScroll} alt='Features' />
            <span>Features</span>
          </Box>
          <Box className={`scroll--nt scroll--news-detail`}>
            <img className='img-fluid ' src={iconScroll} alt='News' />
            <span>News</span>
          </Box>
        </Box>
        {/* news-detail */}
        <Box className={`news--detail transition--1s-slow`}>
          <Box className={`main--xBody`}>
            <Box className={`body--form`}>
              <Box className={`button--viewmore`}>
                  <Link target={"_blank"} to={process.env.REACT_APP_MEDIA_URL}>ニュース一覧</Link>
              </Box>
              
              <Box className={`content-wrapper none-scroll-bar`}>
                
                <Box id='tab-1' className={`tab-1 tab-content active`}>
                  <Box className={`block--body-news`} id=''>

										{Array.isArray(data.allPosts.data) && data.allPosts.data.length > 0 && ( 
										data.allPosts.data.map((post) => (
                      <Link target={"_blank"} to={post.link} className="item--news" key={post.id}>    
                         <Box className={`image-n`}>
                          <img
                            className='img-fluid '
                            src={post.featured_image}
                            alt={post.title}
                          />
                        </Box>

                        <Box className="content-n">
                          <Box component="ul" className="date--cat">
                            <Box component="li">{moment(post.time).format(FORMAT_DATE_CAPITAL)}</Box>
                            <Box component={'li'}>
                              <Box className={`cat-i cat-PRESS`}>{post.categoryPost}</Box>
                            </Box>

                          </Box>
                          <Box className="content--text">
                            <Box className="txt-i">
                              <Box className="text-title">{post.title}</Box>
                              <Box className="text-excerpt">{post.excerpt}</Box>
                            </Box>
                            <Box className="icon-i">
                                <img className="img-fluid" src={iconNtx} alt="icon" />
                            </Box>
                          </Box>
                        </Box>
                      </Link>
										))
									)}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Footer />
        </Box>
        <Box className={`block--scroll block-scroll--news-end transition-1s`}>
          <Box className={`scroll--nt scroll--value-detail`}>
            <img className={`img-fluid imgRotate180`} src={iconScrollBl} alt='Values' />
            <span>Values</span>
          </Box>
        </Box>
        {/* Contact */}
        <Box className={`contact--form transition--1s-slow`}>
          <ContactUs />
        </Box>
      </Box>
      <Box className={`scroll--nt scrollSP scroll--what scroll--top-b `}>
        <img className={`img-fluid`} src={iconScrollBl} alt='What is InFolio?' />
        <span>What is InFolio?</span>
      </Box>
      <Box className={`scroll--nt scrollSP scrollEC `}>
        <img className={`img-fluid imgRotate180`} src={iconScroll} alt='Top' />
        <span>Top</span>
      </Box>
      <Box className={`scroll--nt scrollSP scroll--feature scroll--what-t `}>
        <img className={`img-fluid`} src={iconScroll} alt='InFolioで出来ること' />
        <span>InFolioで出来ること</span>
      </Box>
      <Box className={`scroll--nt scrollSP scroll--what scroll--feature-t`}>
        <img className={`img-fluid imgRotate180`} src={iconScroll} alt='What is InFolio?' />
        <span>What is InFolio?</span>
      </Box>
      <Box className={`scroll--nt scrollSP scroll--value-detail scroll--value-b `}>
        <img className='img-fluid ' src={iconScroll} alt='Values' />
        <span>Values</span>
      </Box>
      <Box className={`scroll--nt scrollSP scroll--feature scroll--feature-v `}>
        <img className={`img-fluid imgRotate180`} src={iconScroll} alt='InFolioで出来ること' />
        <span>InFolioで出来ること</span>
      </Box>
      <Box className={`scroll--nt scrollSP scroll--news-detail scroll--news-b `}>
        <img className='img-fluid ' src={iconScroll} alt='News' />
        <span>News</span>
      </Box>
      <Box className={`scroll--nt scrollSP scroll--value-detail scroll--value-t `}>
        <img className={`img-fluid imgRotate180`} src={iconScroll} alt='Values' />
        <span>Values</span>
      </Box>
    </Box>
  )
}

export default index
