import { RequireAuth, LoginLayout, AppLayout } from '@/components'
import Signin from '@/pages/Signin'
import SettingUser from '@/pages/SettingUser'
import ChangePassword from '@/pages/ChangePassword'
import Members from '@/pages/Members'
import Manager from '@/pages/Manager'
import Home from '@/pages/Home'
import Search from '@/pages/Search'
import InputRequest from '@/pages/RetrieveAccount/InputRequest'
import Reponse from '@/pages/RetrieveAccount/Reponse'
import NotFound from '@/pages/NotFound'
import ResetPassword from '@/pages/ResetPassword'
import ForgotPassword from '@/pages/ForgotPassword'
import Register from '@/pages/Register'
import LandingPage from '@/pages/Landing'
import Shared from '@/pages/Shared'
import Library from '@/pages/Library'
import Benchmark from '@/pages/Benchmark'
import BenchmarkAddEdit from '@/pages/Benchmark/AddEdit'
import BenchmarkDetail from '@/pages/Benchmark/BenchmarkDetail'
import Piecefolio from '@/pages/Piecefolio'
import PiecefolioAddEdit from '@/pages/Piecefolio/AddEdit'
import PiecefolioDetail from '@/pages/Piecefolio/PiecefolioDetail'
import Policy from '@/pages/Policy'
import Law from '@/pages/Law'
import Terms from '@/pages/Terms'
import ErrorBoundary from '@/components/ErrorBoundary'
import BetaTrial from '@/pages/BetaTrial'

const routers = [
  {
    path: '/',
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: <LandingPage />,
      },
      {
        path: '/privacy-policy',
        exact: true,
        element: <Policy />,
      },
      {
        path: '/beta-trial',
        exact: true,
        element: <BetaTrial />,
      },
      {
        path: '/law',
        exact: true,
        element: <Law />,
      },
      {
        path: '/terms-of-service',
        exact: true,
        element: <Terms />,
      },
      {
        path: '/login',
        exact: true,
        element: <LoginLayout />,
        children: [
          {
            path: '/login',
            exact: true,
            element: <Signin />,
          },
        ],
      },
      {
        path: '/auth',
        element: <LoginLayout />,
        children: [
          {
            path: 'forgot-password',
            element: <ForgotPassword />,
          },
          {
            path: 'reset-password',
            element: <ResetPassword />,
          },
        ],
      },
      {
        path: '/retrieve',
        element: <LoginLayout />,
        children: [
          {
            path: 'request-detail-input',
            element: <InputRequest />,
          },
          {
            path: 'response',
            element: <Reponse />,
          },
        ],
      },
      {
        path: '/user',
        element: <LoginLayout />,
        children: [
          {
            path: 'register',
            element: <Register />,
          },
        ],
      },
      {
        path: '/aisearch',
        element: <RequireAuth />,
        children: [
          {
            path: '',
            element: <AppLayout />,
            children: [
              { path: '', element: <Home /> },
              { path: 'lib/:topicId', element: <Search /> },
            ],
          },
        ],
      },
      {
        path: '/library',
        element: <RequireAuth />,
        children: [
          {
            path: '',
            element: <AppLayout />,
            children: [{ path: '', element: <Library /> }],
          },
        ],
      },
      {
        path: '/search',
        element: <RequireAuth />,
        children: [
          {
            path: '',
            element: <AppLayout />,
            children: [
              { path: '', element: <Search /> },
              { path: ':topicId', element: <Search /> },
            ],
          },
        ],
      },
      {
        path: '/shared',
        element: <RequireAuth />,
        children: [
          {
            path: '',
            element: <AppLayout />,
            children: [
              { path: '', element: <Shared /> },
              { path: 'lib/:topicId', element: <Search /> },
              { path: 'ben/:benchmarkId', element: <BenchmarkDetail /> },
              { path: 'ben', element: <BenchmarkAddEdit /> },
              { path: 'ben/:benchmarkId/edit', element: <BenchmarkAddEdit /> },
              { path: 'piece/:piece_id', element: <PiecefolioDetail /> },
              { path: 'piece', element: <PiecefolioAddEdit /> },
              { path: 'piece/:piece_id/edit', element: <PiecefolioAddEdit /> },
            ],
          },
        ],
      },
      {
        path: '/setting',
        element: <RequireAuth />,
        children: [
          {
            path: '',
            element: <AppLayout />,
            children: [{ path: '', element: <SettingUser /> }],
          },
        ],
      },
      {
        path: '/manager',
        element: <RequireAuth />,
        children: [
          {
            path: '',
            element: <AppLayout />,
            children: [{ path: '', element: <Manager /> }],
          },
        ],
      },
      {
        path: '/benchmark',
        element: <RequireAuth />,
        children: [
          {
            path: '',
            element: <AppLayout />,
            children: [
              { path: '', element: <Benchmark /> },
              { path: 'add-new', element: <BenchmarkAddEdit /> },
              { path: ':benchmarkId', element: <BenchmarkDetail /> },
              { path: ':benchmarkId/edit', element: <BenchmarkAddEdit /> },
            ],
          },
        ],
      },
      {
        path: '/piecefolio',
        element: <RequireAuth />,
        children: [
          {
            path: '',
            element: <AppLayout />,
            children: [
              { path: '', element: <Piecefolio /> },
              { path: 'add-new', element: <PiecefolioAddEdit /> },
              { path: ':piece_id', element: <PiecefolioDetail /> },
              { path: ':piece_id/edit', element: <PiecefolioAddEdit /> },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]

export default routers
